<template>
  <div>
    <ys-page-list
        ref="table"
        action="/standard/remote/Search"
        :params="params"
        :tableHeight="height-300"
        noDataText="请输入关键字检索"
        :headerColumns="headerColumns"
        :multiColumn="false"
        :isEmptySelect="isEmptySelect"
        @on-selection-change="checkItem">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="请输入关键词" style="width: 120px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'

export default {
  name: '',
  mixins: [pageList],
  computed: {
    isEmptySelect() {
      return this.limit === 1;
    },
  },
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {minWidth: 120, title: '标准名称', key: 'name', align: 'center'},
        {width: 150, title: '简称', key: 'shortName', align: 'center'},
        {width: 150, title: '标准编码', key: 'code', align: 'center'},
        {width: 100, title: '标准等级', key: 'level', align: 'center', renderConfig: {type: 'baseData', parentName: '标准等级'}},
      ],
      params: {
        keyword: '',
      },
      modal: {}
    }
  },

  mounted() {
    this.getList();
    this.modal = this.$parent.$parent;
  },
  methods: {
    close() {
      this.modal.modShow = false;
    },
    ok() {
      if (this.tableSelectItem.length === 0) {
        this.$Notice.warning({
          title: "操作提示！",
          desc: "请选择至少一条数据！"
        });
        return false;
      }
      if (this.limit !== 0) {
        if (this.tableSelectItem.length > this.limit) {
          this.$Notice.warning({
            title: "操作提示！",
            desc: "最多可选择 " + this.limit + ' 条数据！',
          });
          return false;
        }
      }
      this.$emit('on-ok', this.tableSelectItem);
      this.close();
    }
  }


}
</script>
